<script>
import { BButton, BCard, BCardBody, BCol, BRow, BTable } from 'bootstrap-vue';
import PageTitle from '@/components/PageTitle.vue';
import FlatPickr from 'vue-flatpickr-component';
import api from '@/services/api';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear.vue';
import { prefixZeroToNum } from '@/util/helper';

export default {
    name: 'Statistics',
    components: {
        ProgressLinear,
        PageTitle,
        BRow,
        BCol,
        BCard,
        BCardBody,
        FlatPickr,
        BButton,
        BTable
    },
    data: () => ({
        profits: {
            invoices: [],
            gifts: [],
            loaded: false
        },
        date: {
            start: null,
            end: null
        },
        localLang: localStorage.getItem('lang')
    }),
    computed: {
        invoicesTableHeader() {
            return [
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'price',
                    label: 'Price'
                },
                {
                    key: 'count',
                    label: 'Count'
                },
                {
                    key: 'revenue',
                    label: 'Revenue'
                }
            ];
        },
        giftsTableHeader() {
            return [
                {
                    key: 'name',
                    label: 'Пакеты'
                },
                {
                    key: 'per_price',
                    label: 'Per price'
                },
                {
                    key: 'price',
                    label: 'Price'
                },
                {
                    key: 'count',
                    label: 'Count'
                },
                {
                    key: 'profit',
                    label: 'Profit'
                }
            ];
        },
        computedGifts() {
            if (!this.profits.gifts.length) return;
            return this.profits.gifts.map((gift) => {
                gift.name = gift.gift.name[this.localLang];
                gift.price = gift.gift.price;
                return gift;
            });
        },
        xlsDownloadLink() {
            return `http://dashboard-api.1it.uz/profits/csv?date_start=${this.date.start}&date_end=${this.date.end}`;
        }
    },
    methods: {
        async getProfits() {
            this.profits.items = [];
            this.profits.loaded = false;
            const { data } = await api.statistics.getTotalProfits({
                date_start: this.date.start,
                date_end: this.date.end
            });
            this.profits.invoices = data.invoices.map((invoice) => {
                if (invoice.name == '2601') invoice.name = 'Абонентская плата';
                if (invoice.name == '2602') invoice.name = 'Второй шанс';
                if (invoice.name == '2603') invoice.name = 'Третий шанс';
                if (invoice.name == '2604') invoice.name = 'Премиум шанс 1';
                if (invoice.name == '2605') invoice.name = 'Премиум шанс 2';
                if (invoice.name == '2606') invoice.name = 'Премиум шанс 3';

                return invoice;
            });

            this.profits.gifts = data.gifts.sort((a, b) => {
                if (a.gift.type === b.gift.type) {
                    return a.gift.values < b.gift.values
                        ? -1
                        : a.gift.values > b.gift.values
                        ? 1
                        : 0;
                } else {
                    return a.gift.type < b.gift.type ? -1 : 1;
                }
            });
            this.profits.loaded = true;
        },

        formatTheDateWithoutTime(date) {
            if (!date) return;
            return `${date.getFullYear()}-${prefixZeroToNum(date.getMonth() + 1)}-${prefixZeroToNum(
                date.getDate()
            )}`;
        }
    },
    mounted() {
        if (!this.date.start && !this.date.end) {
            const newDate = this.formatTheDateWithoutTime(new Date());
            this.date.start = this.date.end = newDate;
        }
        this.getProfits();
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>
                {{ $t('navigation.statistics') }}
            </template>
        </page-title>
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="12">
                        <div class="d-flex align-items-end">
                            <div class="statistics__date">
                                <h5>От</h5>
                                <flat-pickr v-model="date.start" class="form-control" />
                            </div>
                            <div class="statistics__date">
                                <h5>До</h5>
                                <flat-pickr v-model="date.end" class="form-control" />
                            </div>
                            <b-button
                                class="btn-icon mx-1"
                                style="height: 35px"
                                variant="primary"
                                @click="getProfits"
                            >
                                <feather-icon icon="SearchIcon" />
                            </b-button>
                            <b-button
                                download
                                style="height: 35px"
                                variant="primary"
                                tag="a"
                                :href="xlsDownloadLink"
                            >
                                Скачать в формате xls
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <progress-linear v-if="!profits.loaded" />
        <template v-else>
            <div class="card mb-2">
                <!--        <a-->
                <!--          download-->
                <!--          href="http://dashboard-api.1it.uz/profits/csv"-->
                <!--        >-->
                <!--          Export to XLS-->
                <!--        </a>-->

                <b-table
                    :fields="invoicesTableHeader"
                    :items="profits.invoices"
                    :responsive="true"
                    striped
                    borderless
                    outlined
                >
                    <template #cell(name)="{ item }">
                        <template v-if="!['total', 'expense', 'revenue'].includes(item.name)">
                            {{ item.name }}
                        </template>
                        <strong v-else>{{ $t(`titles.${item.name}`) }}</strong>
                    </template>
                    <template #cell(price)="{ item }">
                        {{ item.price.toLocaleString('en-US') }} сум
                    </template>
                    <template #cell(revenue)="{ item }">
                        {{ item.revenue.toLocaleString('en-US') }} сум
                    </template>
                </b-table>
            </div>
            <div class="card">
                <b-table
                    :fields="giftsTableHeader"
                    :items="computedGifts"
                    :responsive="true"
                    striped
                    borderless
                    outlined
                >
                    <template #cell(per_price)="{ item }">
                        {{ item.per_price.toLocaleString('en-US') }} сум
                    </template>
                    <template #cell(price)="{ item }">
                        {{ item.price.toLocaleString('en-US') }} сум
                    </template>
                    <template #cell(profit)="{ item }">
                        {{ item.profit.toLocaleString('en-US') }} сум
                    </template>
                </b-table>
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.statistics {
    &__date {
        width: 170px;
        display: flex;
        flex-direction: column;
        position: relative;

        & + & {
            margin-left: 20px;

            &:before {
                content: '';
                position: absolute;
                left: -15px;
                bottom: 17px;
                width: 10px;
                height: 1px;
                background-color: #d8d6de;
            }
        }

        .form-control {
            height: 35px;
        }
    }
}
</style>
