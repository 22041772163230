var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('navigation.statistics'))+" ")]},proxy:true}])}),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-end"},[_c('div',{staticClass:"statistics__date"},[_c('h5',[_vm._v("От")]),_c('flat-pickr',{staticClass:"form-control",model:{value:(_vm.date.start),callback:function ($$v) {_vm.$set(_vm.date, "start", $$v)},expression:"date.start"}})],1),_c('div',{staticClass:"statistics__date"},[_c('h5',[_vm._v("До")]),_c('flat-pickr',{staticClass:"form-control",model:{value:(_vm.date.end),callback:function ($$v) {_vm.$set(_vm.date, "end", $$v)},expression:"date.end"}})],1),_c('b-button',{staticClass:"btn-icon mx-1",staticStyle:{"height":"35px"},attrs:{"variant":"primary"},on:{"click":_vm.getProfits}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1),_c('b-button',{staticStyle:{"height":"35px"},attrs:{"download":"","variant":"primary","tag":"a","href":_vm.xlsDownloadLink}},[_vm._v(" Скачать в формате xls ")])],1)])],1)],1)],1),(!_vm.profits.loaded)?_c('progress-linear'):[_c('div',{staticClass:"card mb-2"},[_c('b-table',{attrs:{"fields":_vm.invoicesTableHeader,"items":_vm.profits.invoices,"responsive":true,"striped":"","borderless":"","outlined":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [(!['total', 'expense', 'revenue'].includes(item.name))?[_vm._v(" "+_vm._s(item.name)+" ")]:_c('strong',[_vm._v(_vm._s(_vm.$t(("titles." + (item.name)))))])]}},{key:"cell(price)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price.toLocaleString('en-US'))+" сум ")]}},{key:"cell(revenue)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.revenue.toLocaleString('en-US'))+" сум ")]}}])})],1),_c('div',{staticClass:"card"},[_c('b-table',{attrs:{"fields":_vm.giftsTableHeader,"items":_vm.computedGifts,"responsive":true,"striped":"","borderless":"","outlined":""},scopedSlots:_vm._u([{key:"cell(per_price)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.per_price.toLocaleString('en-US'))+" сум ")]}},{key:"cell(price)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price.toLocaleString('en-US'))+" сум ")]}},{key:"cell(profit)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.profit.toLocaleString('en-US'))+" сум ")]}}])})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }